
.main-wrapper {
    height: 100%;
    padding: 0 20px 20px 20px;
    box-sizing: border-box;
    .goods-text-table {
        .answer {
            display: flex;
            align-items: center;
            img {
                width: 80px;
                height: 80px;
                margin-right: 15px;
                cursor: pointer;
            }
            .text {
                flex: 1;
                width: 1%;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
            }
        }
    }
    .link-box {
        .view-detail {
            ::v-deep .el-link--inner {
                color: #1AB163;
            }
        }
    }
    .goods-img-dialog {
        .upload-file-content {
            display: flex;
            justify-content: center;
            width: 100%;
            height: 445px;
            .big-img {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 444px;
                height: 444px;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
    }
    .goods-score-dialog {
        .upload-file-content {
            display: flex;
            flex-direction: column;
            height: 383px;
        }
    }
}
.answer-detail-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #F1F5FF;
    .go-back {
        margin-bottom: 12px;
    }
}
